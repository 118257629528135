export const customers = [
  {
    title: 'CRM Omie',
    image: 'crm-omie.png',
    subtitle: 'Internal System',
    description: 'In service to Omie',
    badges: ['Web Site', 'Vue JS'],
  },
  {
    title: 'Sharelink',
    image: 'sharelink.png',
    subtitle: 'Web site',
    description: 'In service to INXP',
    badges: ['Web Site', 'React', 'UX'],
  },
  {
    title: 'Tatieli Ramos',
    image: 'tatielirb.png',
    subtitle: 'Portfolio',
    description: 'In service to Tatieli',
    badges: ['Web Site', 'React', 'UX'],
  },
  {
    title: 'Atlas',
    image: 'atlas-warren.png',
    subtitle: 'Internal System',
    description: 'In service to Warren',
    badges: ['Web Site', 'Vue JS'],
  },
  {
    title: 'Warren',
    image: 'warren.png',
    subtitle: 'Site Warren',
    description: 'In service to Warren',
    badges: ['Web Site', 'Vue JS'],
  },
  {
    title: 'Warren',
    image: 'warren-login.png',
    subtitle: 'Client Platform Warren',
    description: 'In service to Warren',
    badges: ['Web Site', 'Vue JS'],
  },
  {
    title: 'Petrobras',
    image: 'seguindo-em-frente-petrobras.jpg',
    subtitle: 'Moving Forward 2017',
    description: 'In service to HUIA e H3Haus',
    badges: ['Web Site', 'Word Press'],
  },
  {
    title: 'NOW3',
    image: 'now-w3haus.jpg',
    subtitle: 'Website of the Course',
    description: 'In service to HUIA and H3Haus',
    badges: ['Web Site'],
  },
  {
    title: 'Sansung',
    image: 'sansung-nextinnovation.jpg',
    subtitle: 'Next Innovation',
    description: 'In service to HUIA and H3Haus',
    badges: ['Web Site'],
  },
  {
    title: 'Tintas Renner',
    image: 'tintas-renner.jpg',
    subtitle: 'Site Decor',
    description: 'In service to HUIA',
    badges: ['Web Site'],
  },
  {
    title: 'Sansung',
    image: 'sansung-galaxy-a.jpg',
    subtitle: 'Galaxy A 2017',
    description: 'In service to HUIA and H3Haus',
    badges: ['Web Site'],
  },
  {
    title: 'Cartórios de Protesto RS',
    image: 'cartoriodeprotestors.jpg',
    subtitle: 'Site 2017',
    description: 'In service to HUIA',
    badges: ['Web Site'],
  },
  {
    title: 'SENAR',
    image: 'senar.jpg',
    subtitle: 'Expointer 2016',
    description: 'In service to HUIA',
    badges: ['Web Site', '.NET'],
  },
  {
    title: 'Petrobras',
    image: 'hackathon-petrobras.jpg',
    subtitle: 'Hackathon Petrobras 1° Ed',
    description: 'In service to HUIA and H3Haus',
    badges: ['Web Site', 'Word Press'],
  },
  {
    title: 'Tintas Renner',
    image: 'tintas-renner-majestic.jpg',
    subtitle: 'Site Majestic',
    description: 'In service to HUIA',
    badges: ['Web Site'],
  },
  {
    title: 'ANITTA',
    image: 'anittaagenda.jpg',
    subtitle: 'Temporary Schedule',
    description: 'In service to Base Digital',
    badges: ['Web Site'],
  },
  {
    title: 'MUSIKERIA',
    image: 'musekeria.jpg',
    subtitle: 'Site 2016',
    description: 'In service to Base Digital',
    badges: ['Web Site', 'Word Press'],
  },
  {
    title: 'FAVORITA',
    image: 'favorita_planejado_2015.jpg',
    subtitle: 'Site 2015',
    description: 'In service to Aldeia',
    badges: ['Web Site', 'Word Press'],
  },
  {
    title: 'INOVAÇÃO FIERS',
    image: 'inovacao_2015_fiergs.jpg',
    subtitle: '2º Innovation Forum',
    description: 'In service to Aldeia',
    badges: ['Web Site'],
  },
  {
    title: 'TECNOPUC',
    image: 'tecnopuc_10anos.jpg',
    subtitle: 'Digital Decade Book',
    description: 'In service to Aldeia',
    badges: ['Web Site'],
  },
  {
    title: 'COLCCI',
    image: 'colcci_inverno2015.jpg',
    subtitle: 'Winter Collection 2015',
    description: 'In service to Aldeia',
    badges: ['Web Site', 'Word Press'],
  },
  {
    title: 'Forum',
    image: 'forum-web-site.png',
    subtitle: 'Winter Collection 2015',
    description: 'In service to Aldeia',
    badges: ['Web Site', 'Word Press'],
  },
  {
    title: 'COLCCI SPFW',
    image: 'colcci_spfw_2015.jpg',
    subtitle: 'SPFW Coverage of Colcci',
    description: 'In service to Aldeia',
    badges: ['Web Site'],
  },
  {
    title: 'ELEVATO',
    image: 'elevato.jpg',
    subtitle: 'Montelupo',
    description: 'In service to Aldeia',
    badges: ['Web Site', 'Word Press'],
  },
  {
    title: 'COLCCI',
    image: 'power-skinnyjeans-edition.jpg',
    subtitle: 'Site Power Skinny Jeans',
    description: 'In service to Aldeia',
    badges: ['Web Site'],
  },
  {
    title: 'ARIA HT',
    image: 'ariaht.jpg',
    subtitle: 'Site 2015',
    description: 'In service to Aldeia',
    badges: ['Web Site', 'Word Press'],
  },
  {
    title: 'DANIEL CASSIN',
    image: 'danielCassin_natalmaisbonito2015.jpg',
    subtitle: 'Most Beautiful Christmas 2015',
    description: 'In service to Aldeia',
    badges: ['Web Site', 'Word Press'],
  },
  {
    title: 'DELL ANNO BEST',
    image: 'dellanno_best_2015.jpg',
    subtitle: 'Campaign 2015',
    description: 'In service to Aldeia',
    badges: ['Web Site', 'Word Press'],
  },
  {
    title: 'MUDA MUNDO',
    image: 'mudamundo.jpg',
    subtitle: 'Site 2015',
    description: 'In service to Aldeia',
    badges: ['Web Site', 'Word Press'],
  },
  {
    title: 'PEQUENOS VALENTES',
    image: 'pequenosValentes_HMD.jpg',
    subtitle: 'HMD Project',
    description: 'In service to Aldeia',
    badges: ['Web Site', 'Word Press'],
  },
  {
    title: 'DONNA WALK RUN',
    image: 'dona_walk_run_paquetaesportes_2014.jpg',
    subtitle: 'Paqueta Esportes 2014',
    description: 'In service to Aldeia',
    badges: ['Web Site'],
  },
  {
    title: 'WALK RUN',
    image: 'walkrun_paquetaesportes_2014.jpg',
    subtitle: 'Paqueta Esportes 2014',
    description: 'In service to Aldeia',
    badges: ['Web Site'],
  },
  {
    title: '#QUEROVERGOL',
    image: 'queroVerGol_paquetaesporte.jpg',
    subtitle: 'Paquetá Sports campaign World Cup 2014',
    description: 'In service to Aldeia',
    badges: ['Web Site'],
  },
  {
    title: 'MEU NATAL FAVORITO',
    image: 'natalfavorita.jpg',
    subtitle: 'Christmas Campaign',
    description: 'In service to Aldeia',
    badges: ['Web Site'],
  },
  {
    title: 'SOMMER',
    image: 'sommer_sabrinasato.jpg',
    subtitle: '2015 Collection Campaign',
    description: 'In service to Aldeia',
    badges: ['Web Site'],
  },
  {
    title: 'NEW MÓVEIS',
    image: 'new_moveis_sorte.jpg',
    subtitle: 'Sorte sua',
    description: 'In service to Aldeia',
    badges: ['Web Site'],
  },
  {
    title: 'TARTONI',
    image: 'tartoni.jpg',
    subtitle: 'Promotional Website 2015',
    description: 'In service to Aldeia',
    badges: ['Web Site'],
  },
  {
    title: 'GARMIN',
    image: 'garmin_paquetaesportes.jpg',
    subtitle: 'Site 2014',
    description: 'In service to Aldeia',
    badges: ['Web Site'],
  },
  {
    title: 'SOMMER',
    image: 'sommer2015.jpg',
    subtitle: 'Summer 2016 Campaign',
    description: 'In service to Aldeia',
    badges: ['Web Site', 'Word Press'],
  },
  {
    title: 'MINHA FAVORITA',
    image: 'minha-favorita.jpg',
    subtitle: 'Decor website',
    description: 'In service to Aldeia',
    badges: ['Web Site', 'Word Press'],
  },
  {
    title: 'EDUCAR',
    image: 'educarIntercampios.jpg',
    subtitle: 'School Website',
    description: 'In service to POP3',
    badges: ['Web Site'],
  },
  {
    title: 'GLAUCCIO DUTRA',
    image: 'glaucciodutra.jpg',
    subtitle: 'Portfólio',
    description: 'In service to POP3',
    badges: ['Web Site', 'Word Press'],
  },
]
